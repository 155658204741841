import { ProdutosContratados } from 'models/atendimento/types'
import { Tabulacao } from 'models/types'
import { maskCPF, maskDate } from 'util/masks'

export const produtos = [
  {
    id: '1',
    nome: 'Empréstimo consignado',
    banco: 'Itaú',
    valorTotal: '10000.00',
    qtdeParcelas: 10,
    valorParcela: '1000.00',
    beneficio: '536248954 - Aposentadoria'
  },
  {
    id: '2',
    nome: 'Refinanciamento',
    banco: 'Nubank',
    valorTotal: '8000.00',
    qtdeParcelas: 10,
    valorParcela: '800.00',
    beneficio: '536248954 - Pensionista'
  },
  {
    id: '3',
    nome: 'Empréstimo consignado novo',
    banco: 'BMG',
    valorTotal: '16000.00',
    qtdeParcelas: 2,
    valorParcela: '8000.00',
    beneficio: '536248954 - CLT'
  },
  {
    id: '4',
    nome: 'Cartão consignado',
    banco: 'Inter',
    valorTotal: '7000.00',
    qtdeParcelas: 10,
    valorParcela: '700.00',
    beneficio: '536248954 - Servidor'
  }
]

export const atendimentosTable = [
  {
    clienteId: 'bf30fabb-3bad-46ea-93bf-ca90b750babf',
    cliente: {
      valorRendaIndividual: 2500,
      profissao: null,
      pessoaId: '8bb91b0d-4c35-48cc-b073-402185f7a5a1',
      margemConsignadoCartao: 60.6,
      escolaridadeId: 'edff360f-fc85-4df0-97a4-5520718a82df',
      valorDesejado: null,
      margemDisponivel: 424.2,
      bmg: null,
      sexo: 'F',
      estadoCivil: 4,
      nomeConjuge: null,
      cidadeNascimento: 'COLINA',
      ufNascimento: 'SP',
      nacionalidade: 'Brasileira',
      pessoa: {
        flagJuridica: false,
        nome: 'MARIA HELENA LOPES ALVES',
        cpfCnpj: '17871523830',
        nomeMae: 'ISABEL FERREIRA LOPES',
        nomePai: 'MANUEL LOPES',
        dataNascimento: '1958-04-09T00:00:00',
        ie: null,
        im: null,
        rg: '16376793',
        rgOrgaoEmissorId: '0f599fe9-9bea-4fb5-933e-df1f7c70d655',
        isento: null,
        nomeFantasia: null,
        estadoOrgaoEmissorId: 'd5d9f657-c099-4a9a-b36a-25e95f57f5ad',
        dataEmissao: '2005-08-05T00:00:00',
        enderecos: null,
        telefones: [
          {
            pessoaId: '8bb91b0d-4c35-48cc-b073-402185f7a5a1',
            ddd: '16',
            numero: '996361235',
            tipoTelefoneId: '1c18f432-52c5-4385-a058-a46c1620af97',
            tipoTelefone: null,
            simulador: true,
            classificacaoTelefone: null,
            telefonesCondenados: null,
            id: '7e0d2d35-24a5-4b1d-a9be-ca33b41f174f',
            ativo: true,
            usuario: null,
            idUsuarioCriacao: '00000000-0000-0000-0000-000000000000',
            dataCriacao: '2022-05-09T16:32:46.2422987',
            usuarioAlteracao: null,
            idUsuarioUltimaAlteracao: null,
            dataUltimaAlteracao: '2022-05-15T12:01:20.1204667'
          }
        ],
        usuariosBancos: [],
        formulariosAtendimento: null,
        contaCorrente: null,
        estadoOrgaoEmissor: null,
        rgOrgaoEmissor: null,
        atendimentos: null,
        telefonesCondenados: null,
        id: '8bb91b0d-4c35-48cc-b073-402185f7a5a1',
        ativo: true,
        usuario: null,
        idUsuarioCriacao: '00000000-0000-0000-0000-000000000000',
        dataCriacao: '2022-05-09T16:32:46.2422906',
        usuarioAlteracao: null,
        idUsuarioUltimaAlteracao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
        dataUltimaAlteracao: '2022-06-25T11:55:20.3542022'
      },
      clienteMatricula: null,
      convenios: null,
      escolaridade: null,
      abandonoCliente: null,
      atendimentos: [],
      id: 'bf30fabb-3bad-46ea-93bf-ca90b750babf',
      ativo: true,
      usuario: null,
      idUsuarioCriacao: '4da214f4-c5dc-4967-ab89-0e036da336d0',
      dataCriacao: '2022-05-09T16:33:13.2981573',
      usuarioAlteracao: null,
      idUsuarioUltimaAlteracao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
      dataUltimaAlteracao: '2022-06-25T16:23:18.0841946'
    },
    tabulacaoId: null,
    tabulacao: null,
    canalAtendimentoId: '8e631901-597e-461a-a78c-38dd4ebaa421',
    canalAtendimento: {
      descricao: 'Telefone',
      atendimentos: [],
      id: '8e631901-597e-461a-a78c-38dd4ebaa421',
      ativo: true,
      usuario: null,
      idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
      dataCriacao: '2021-10-14T09:57:58.5287385',
      usuarioAlteracao: null,
      idUsuarioUltimaAlteracao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
      dataUltimaAlteracao: null
    },
    atendenteId: 'deaea8ec-4ee5-4cc9-b986-17532d950f6f',
    atendente: {
      flagJuridica: false,
      nome: 'Pedro Henrique Marchesi dos Santos.',
      cpfCnpj: '51063575869',
      nomeMae: 'Edilaine Cristina',
      nomePai: 'Andre Luis',
      dataNascimento: '2000-11-29T00:00:00',
      ie: null,
      im: null,
      rg: '552311340',
      rgOrgaoEmissorId: '0f599fe9-9bea-4fb5-933e-df1f7c70d655',
      isento: null,
      nomeFantasia: null,
      estadoOrgaoEmissorId: 'd5d9f657-c099-4a9a-b36a-25e95f57f5ad',
      dataEmissao: null,
      cliente: null,
      enderecos: null,
      telefones: null,
      usuariosBancos: [],
      formulariosAtendimento: null,
      contaCorrente: null,
      estadoOrgaoEmissor: null,
      rgOrgaoEmissor: null,
      atendimentos: [],
      telefonesCondenados: null,
      id: 'deaea8ec-4ee5-4cc9-b986-17532d950f6f',
      ativo: true,
      usuario: null,
      idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
      dataCriacao: '2022-03-19T14:58:15.2518834',
      usuarioAlteracao: null,
      idUsuarioUltimaAlteracao: null,
      dataUltimaAlteracao: null
    },
    polaridadeId: null,
    polaridade: null,
    atendimentoAgendado: null,
    id: 'c40c02c9-7101-45fe-bb3a-0a57287d19b6',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-06-25T15:49:07.0148368',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null
  },
  {
    clienteId: 'bf30fabb-3bad-46ea-93bf-ca90b750babf',
    cliente: {
      valorRendaIndividual: 2500,
      profissao: null,
      pessoaId: '8bb91b0d-4c35-48cc-b073-402185f7a5a1',
      margemConsignadoCartao: 60.6,
      escolaridadeId: 'edff360f-fc85-4df0-97a4-5520718a82df',
      valorDesejado: null,
      margemDisponivel: 424.2,
      bmg: null,
      sexo: 'F',
      estadoCivil: 4,
      nomeConjuge: null,
      cidadeNascimento: 'COLINA',
      ufNascimento: 'SP',
      nacionalidade: 'Brasileira',
      pessoa: {
        flagJuridica: false,
        nome: 'MARIA HELENA LOPES ALVES',
        cpfCnpj: '17871523830',
        nomeMae: 'ISABEL FERREIRA LOPES',
        nomePai: 'MANUEL LOPES',
        dataNascimento: '1958-04-09T00:00:00',
        ie: null,
        im: null,
        rg: '16376793',
        rgOrgaoEmissorId: '0f599fe9-9bea-4fb5-933e-df1f7c70d655',
        isento: null,
        nomeFantasia: null,
        estadoOrgaoEmissorId: 'd5d9f657-c099-4a9a-b36a-25e95f57f5ad',
        dataEmissao: '2005-08-05T00:00:00',
        enderecos: null,
        telefones: [
          {
            pessoaId: '8bb91b0d-4c35-48cc-b073-402185f7a5a1',
            ddd: '16',
            numero: '996361235',
            tipoTelefoneId: '1c18f432-52c5-4385-a058-a46c1620af97',
            tipoTelefone: null,
            simulador: true,
            classificacaoTelefone: null,
            telefonesCondenados: null,
            id: '7e0d2d35-24a5-4b1d-a9be-ca33b41f174f',
            ativo: true,
            usuario: null,
            idUsuarioCriacao: '00000000-0000-0000-0000-000000000000',
            dataCriacao: '2022-05-09T16:32:46.2422987',
            usuarioAlteracao: null,
            idUsuarioUltimaAlteracao: null,
            dataUltimaAlteracao: '2022-05-15T12:01:20.1204667'
          }
        ],
        usuariosBancos: [],
        formulariosAtendimento: null,
        contaCorrente: null,
        estadoOrgaoEmissor: null,
        rgOrgaoEmissor: null,
        atendimentos: null,
        telefonesCondenados: null,
        id: '8bb91b0d-4c35-48cc-b073-402185f7a5a1',
        ativo: true,
        usuario: null,
        idUsuarioCriacao: '00000000-0000-0000-0000-000000000000',
        dataCriacao: '2022-05-09T16:32:46.2422906',
        usuarioAlteracao: null,
        idUsuarioUltimaAlteracao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
        dataUltimaAlteracao: '2022-06-25T11:55:20.3542022'
      },
      clienteMatricula: null,
      convenios: null,
      escolaridade: null,
      abandonoCliente: null,
      atendimentos: [],
      id: 'bf30fabb-3bad-46ea-93bf-ca90b750babf',
      ativo: true,
      usuario: null,
      idUsuarioCriacao: '4da214f4-c5dc-4967-ab89-0e036da336d0',
      dataCriacao: '2022-05-09T16:33:13.2981573',
      usuarioAlteracao: null,
      idUsuarioUltimaAlteracao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
      dataUltimaAlteracao: '2022-06-25T16:23:18.0841946'
    },
    tabulacaoId: null,
    tabulacao: null,
    canalAtendimentoId: '8e631901-597e-461a-a78c-38dd4ebaa421',
    canalAtendimento: {
      descricao: 'Telefone',
      atendimentos: [],
      id: '8e631901-597e-461a-a78c-38dd4ebaa421',
      ativo: true,
      usuario: null,
      idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
      dataCriacao: '2021-10-14T09:57:58.5287385',
      usuarioAlteracao: null,
      idUsuarioUltimaAlteracao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
      dataUltimaAlteracao: null
    },
    atendenteId: 'deaea8ec-4ee5-4cc9-b986-17532d950f6f',
    atendente: {
      flagJuridica: false,
      nome: 'Pedro Henrique Marchesi dos Santos.',
      cpfCnpj: '51063575869',
      nomeMae: 'Edilaine Cristina',
      nomePai: 'Andre Luis',
      dataNascimento: '2000-11-29T00:00:00',
      ie: null,
      im: null,
      rg: '552311340',
      rgOrgaoEmissorId: '0f599fe9-9bea-4fb5-933e-df1f7c70d655',
      isento: null,
      nomeFantasia: null,
      estadoOrgaoEmissorId: 'd5d9f657-c099-4a9a-b36a-25e95f57f5ad',
      dataEmissao: null,
      cliente: null,
      enderecos: null,
      telefones: null,
      usuariosBancos: [],
      formulariosAtendimento: null,
      contaCorrente: null,
      estadoOrgaoEmissor: null,
      rgOrgaoEmissor: null,
      atendimentos: [],
      telefonesCondenados: null,
      id: 'deaea8ec-4ee5-4cc9-b986-17532d950f6f',
      ativo: true,
      usuario: null,
      idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
      dataCriacao: '2022-03-19T14:58:15.2518834',
      usuarioAlteracao: null,
      idUsuarioUltimaAlteracao: null,
      dataUltimaAlteracao: null
    },
    polaridadeId: null,
    polaridade: null,
    atendimentoAgendado: null,
    id: 'b8de0bd1-7d42-49ff-976c-2659fde931b4',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-06-25T16:20:46.0691702',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null
  }
]

export const tabulacoesButtons: Tabulacao[] = [
  {
    descricao: 'Agendar',
    retirarClienteCampanha: true,
    condenarTelefone: false,
    pularParaProximoTelefone: true,
    finalizarAtendimento: true,
    polaridadeId: '2d514c20-292f-4097-88cc-818e984e35a0',
    polaridade: null,
    localizacaoId: '21b6ec70-2392-4879-b7d5-456155c4c31e',
    localizacao: null,
    periodoAgendamentoId: '7ed0ed25-152f-4e93-b38a-ed1706438e93',
    habilitarEscolhaTipoAgendamento: true,
    observacoes: null,
    periodoAgendamento: null,
    atendimentos: null,
    id: '6d6af1fe-6db5-446b-9c82-26a84d30e783',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-06-27T09:17:02.073',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null,
    personalizado: false
  },
  {
    descricao: 'Sem interesse',
    retirarClienteCampanha: true,
    condenarTelefone: false,
    pularParaProximoTelefone: true,
    finalizarAtendimento: true,
    polaridadeId: '2d514c20-292f-4097-88cc-818e984e35a0',
    polaridade: null,
    localizacaoId: '21b6ec70-2392-4879-b7d5-456155c4c31e',
    localizacao: null,
    periodoAgendamentoId: null,
    habilitarEscolhaTipoAgendamento: true,
    observacoes: null,
    periodoAgendamento: null,
    atendimentos: null,
    id: '84eebb60-3b21-49f6-8176-f783ad3e0002',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-06-28T08:26:58.583',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null,
    personalizado: false
  },
  {
    descricao: 'Telefone Errado',
    retirarClienteCampanha: false,
    condenarTelefone: true,
    pularParaProximoTelefone: true,
    finalizarAtendimento: true,
    polaridadeId: '2f9bccb8-845b-47bb-91bf-343b97907a20',
    polaridade: null,
    localizacaoId: '2469674c-643e-4d21-b697-b6a02a5820d4',
    localizacao: null,
    periodoAgendamentoId: null,
    habilitarEscolhaTipoAgendamento: true,
    observacoes: null,
    periodoAgendamento: null,
    atendimentos: null,
    id: 'bbef6335-99d5-4155-bf2f-708eb7bf3b1b',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-06-28T08:27:30.563',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null,
    personalizado: false
  },
  {
    descricao: 'teste',
    retirarClienteCampanha: true,
    condenarTelefone: true,
    pularParaProximoTelefone: true,
    finalizarAtendimento: true,
    polaridadeId: '2d514c20-292f-4097-88cc-818e984e35a0',
    polaridade: null,
    localizacaoId: '2469674c-643e-4d21-b697-b6a02a5820d4',
    localizacao: null,
    periodoAgendamentoId: '6acd739b-a2d8-49d8-9ce8-b65048b441c9',
    habilitarEscolhaTipoAgendamento: true,
    observacoes: null,
    periodoAgendamento: null,
    atendimentos: null,
    id: '5adf62fd-f4f9-471b-8b07-fe5f75ef6484',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-06-27T15:01:54.63',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null,
    personalizado: false
  },
  {
    descricao: 'Vai pensar',
    retirarClienteCampanha: false,
    condenarTelefone: false,
    pularParaProximoTelefone: true,
    finalizarAtendimento: true,
    polaridadeId: 'ee0a2d96-f25c-41d4-b29d-645ecac1751d',
    polaridade: null,
    localizacaoId: '21b6ec70-2392-4879-b7d5-456155c4c31e',
    localizacao: null,
    periodoAgendamentoId: '6dd84036-ab72-482f-8b92-1fcc05ea1c32',
    habilitarEscolhaTipoAgendamento: true,
    observacoes: null,
    periodoAgendamento: null,
    atendimentos: null,
    id: '727a6212-97b8-4580-85f1-7775901299fa',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-06-28T08:25:02.843',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null,
    personalizado: false
  }
]

export const produtosContratados: ProdutosContratados[] = [
  {
    cpf: '17871523830',
    nomeCliente: 'MARIA HELENA LOPES ALVES RIBEIRO',
    numeroProposta: '76678751',
    produto: 60,
    valorTotal: 0,
    quantidadeParcelas: 0,
    valorParcela: 7000,
    valorLiberado: 0,
    usuarioInstituicao: null,
    status: 0,
    banco: 'BMG',
    numeroMatricula: '1887701017',
    id: '3fbb8e21-e641-4c0f-8c84-60560d19c67b',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-07-05T18:00:24.5779012',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null
  },
  {
    cpf: '17871523830',
    nomeCliente: 'MARIA HELENA LOPES ALVES RIBEIRO',
    numeroProposta: '76678751',
    produto: 10,
    valorTotal: 400,
    quantidadeParcelas: 0,
    valorParcela: 0,
    valorLiberado: 0,
    usuarioInstituicao: null,
    status: 0,
    banco: 'BMG',
    numeroMatricula: '1887701017',
    id: '3fbb8e21-e641-4c0f-8c84-60560d19c67b',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-07-05T18:00:24.5779012',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null
  },
  {
    cpf: '17871523830',
    nomeCliente: 'MARIA HELENA LOPES ALVES RIBEIRO',
    numeroProposta: '76678751',
    produto: 40,
    valorTotal: 0,
    quantidadeParcelas: 0,
    valorParcela: 9500,
    valorLiberado: 0,
    usuarioInstituicao: null,
    status: 0,
    banco: 'BMG',
    numeroMatricula: '1887701017',
    id: '3fbb8e21-e641-4c0f-8c84-60560d19c67b',
    ativo: true,
    usuario: null,
    idUsuarioCriacao: 'f19399e2-b158-4f5c-b948-859b4e97a4b5',
    dataCriacao: '2022-07-05T18:00:24.5779012',
    usuarioAlteracao: null,
    idUsuarioUltimaAlteracao: null,
    dataUltimaAlteracao: null
  }
]
export const TopData = [
  { name: 'trocar1', id: 1 },
  { name: 'trocar2', id: 2 },
  { name: 'trocar3', id: 3 },
  { name: 'trocar4', id: 4 },
  { name: 'trocar5', id: 5 }
]
export const cardSale3 = [
  { name: 'value', number: 'number', percent: '%' },
  { name: 'value', number: 'number', percent: '%' },
  { name: 'value', number: 'number', percent: '%' }
]
export const cardSale2 = [
  { name: 'value', number: 'number', percent: '%' },
  { name: 'value', number: 'number', percent: '%' }
]

export const arquivoUploads = [
  {
    id: String(Math.random()),
    nomeDoArquivo: 'image 1',
    data: '06/07/22',
    usuario: 'John Doe',
    tipoDoArquivo: 'png'
  },
  {
    id: String(Math.random()),
    nomeDoArquivo: 'image 2',
    data: '06/07/22',
    usuario: 'John Doe',
    tipoDoArquivo: 'png'
  },
  {
    id: String(Math.random()),
    nomeDoArquivo: 'image 3',
    data: '06/07/22',
    usuario: 'John Doe',
    tipoDoArquivo: 'png'
  },
  {
    id: String(Math.random()),
    nomeDoArquivo: 'image 4',
    data: '06/07/22',
    usuario: 'John Doe',
    tipoDoArquivo: 'png'
  },
  {
    id: String(Math.random()),
    nomeDoArquivo: 'image 5',
    data: '06/07/22',
    usuario: 'John Doe',
    tipoDoArquivo: 'png'
  }
]

export const grupos = [
  {
    id: String(Math.random()),
    nome: 'grupo 1',
    descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
    lojasParticipantes: ['loja 1', 'loja 2', 'loja 3'],
    quantidadeLojas: '3'
  },
  {
    id: String(Math.random()),
    nome: 'grupo 2',
    descricao: 'Vivamus tincidunt interdum ligula, ',
    lojasParticipantes: ['loja 1', 'loja 2', 'loja 3'],
    quantidadeLojas: '3'
  },
  {
    id: String(Math.random()),
    nome: 'grupo 3',
    descricao: ' dolor sit amet, consectetur ',
    lojasParticipantes: ['loja 1', 'loja 2', 'loja 3'],
    quantidadeLojas: '3'
  },
  {
    id: String(Math.random()),
    nome: 'grupo 4',
    descricao: 'tincidunt interdum ligula, ',
    lojasParticipantes: ['loja 1', 'loja 2', 'loja 3'],
    quantidadeLojas: '3'
  }
]

export const bank = [
  {
    id: 1,
    bankName: 'Banco Neon'
  },
  {
    id: 2,
    bankName: 'Banco Original'
  },
  {
    id: 2,
    bankName: 'Banco pan'
  }
]

export const blacklist = [
  {
    id: String(Math.random()),
    cpf: maskCPF('12345678912'),
    nomecliente: 'cliente 1',
    dataInclusão: maskDate(String(new Date()), 'DD/MM/YYYY'),
    usuario: 'admin'
  },
  {
    id: String(Math.random()),
    cpf: maskCPF('12345678912'),
    nomecliente: 'cliente 1',
    dataInclusão: maskDate(String(new Date()), 'DD/MM/YYYY'),
    usuario: 'admin'
  },
  {
    id: String(Math.random()),
    cpf: maskCPF('12345678912'),
    nomecliente: 'cliente 1',
    dataInclusão: maskDate(String(new Date()), 'DD/MM/YYYY'),
    usuario: 'admin'
  },
  {
    id: String(Math.random()),
    cpf: maskCPF('12345678912'),
    nomecliente: 'cliente 1',
    dataInclusão: maskDate(String(new Date()), 'DD/MM/YYYY'),
    usuario: 'admin'
  },
  {
    id: String(Math.random()),
    cpf: maskCPF('12345678912'),
    nomecliente: 'cliente 1',
    dataInclusão: maskDate(String(new Date()), 'DD/MM/YYYY'),
    usuario: 'admin'
  },
  {
    id: String(Math.random()),
    cpf: maskCPF('12345678912'),
    nomecliente: 'cliente 1',
    dataInclusão: maskDate(String(new Date()), 'DD/MM/YYYY'),
    usuario: 'admin'
  },
  {
    id: String(Math.random()),
    cpf: maskCPF('12345678912'),
    nomecliente: 'cliente 1',
    dataInclusão: maskDate(String(new Date()), 'DD/MM/YYYY'),
    usuario: 'admin'
  },
  {
    id: String(Math.random()),
    cpf: maskCPF('12345678912'),
    nomecliente: 'cliente 1',
    dataInclusão: maskDate(String(new Date()), 'DD/MM/YYYY'),
    usuario: 'admin'
  }
]
