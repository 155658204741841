import { handlerError } from 'util/handlerError'
import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery } from 'redux-saga/effects'
import { Carteira, INICIO, TYPES } from './types'
import * as atendimentoActions from 'store/modules/atendimento/actions'
import * as inicioController from 'controller/inicioController'
import * as inicioActions from 'store/modules/inicio/actions'
import * as loadingActions from 'store/modules/loading/actions'
import { removeMaskCPF } from 'util/masks'
import { Telefone } from 'models/types'
import moment from 'moment'
import history from 'util/history'
import { InfosChamada } from 'models/atendimento/types'

interface GenericProps {
  type: string
}

function* getQuantidadeLeads(): SagaIterator {
  try {
    const response = yield call(inicioController.getQuantidadeLeads)
    yield put(
      inicioActions.setQuantidadeLeads(response?.data?.content?.length || 0)
    )
  } catch (error) {
    handlerError(error)
  }
}

function* iniciarAtendimentoLead(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(inicioController.iniciarAtendimentoLead)
    yield put(
      atendimentoActions.startAtendimento(response?.data?.content?.cpfCnpj, 1)
    )
    const telefones: InfosChamada['telefones'] = []
    response?.data?.content?.telefones.forEach((telefone: Telefone) => {
      telefones.push({
        numero: `(${telefone.ddd}) ${telefone.numero}`,
        whatsapp: telefone.whatsapp,
        telefoneId: telefone.telefoneId
      })
    })
    yield put(
      atendimentoActions.setInfosContato(
        {
          nome: response?.data?.content?.nome,
          dataNascimento: moment(
            response?.data?.content?.dataNascimento
          ).format('DD/MM/YYYY'),
          cidade: response?.data?.content?.cidade,
          sexo: response?.data?.content?.sexo,
          clienteRiber: response?.data?.content?.clienteRiber,
          infosChamada: {
            telefones: telefones,
            dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
            horarioAgendamento: moment().format('HH:mm'),
            numeroChamada: [],
            observacoesAgendamento: '',
            tabulacaoChamada: null,
            tipoAgendamento: '',
            emChamada: false
          },
          atendimentoId: response?.data?.content?.atendimentoId,
          tipoProduto: response?.data?.content?.tipoProduto,
          campanhaCrmId: response?.data?.content?.campanhaCrmId,
          atendimentoAgendadoId: response?.data?.content?.atendimentoAgendadoId,
          consultorId: null,
          tipoAtendimento: 3
        },
        response?.data?.content?.cpfCnpj
      )
    )
    if (window.location.pathname.toLowerCase() !== '/atendimento') {
      history.push('/atendimento')
    }
    yield put(
      atendimentoActions.setModalAtendimentoAgendado(
        !!response?.data?.content?.atendimentoAgendadoId,
        removeMaskCPF(response?.data?.content?.cpfCnpj)
      )
    )
  } catch (error) {
    handlerError(error, 'Não foi possível iniciar o atendimento da Lead!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getCarteiras(): SagaIterator {
  try {
    yield put(inicioActions.setLoading('carteiras', true))
    const response = yield call(inicioController.getCarteiras)
    const agrupamentoHorario = response?.data?.content?.reduce(
      (prev: { [x: string]: Carteira[] }, item: Carteira) => {
        const consultorId: string = item.consultorId
        if (!prev[consultorId]) {
          prev[consultorId] = []
        }
        prev[consultorId].push(item)
        return prev
      },
      []
    )
    const groupArrays: INICIO['carteiras'] = Object.keys(
      agrupamentoHorario
    ).map((consultorId: string) => {
      return {
        consultorId: consultorId,
        nomeConsultor: agrupamentoHorario[consultorId]?.[0]?.nomeConsultor,
        carteirasConsultor: agrupamentoHorario[consultorId]
      }
    })
    yield put(inicioActions.setCarteiras(groupArrays))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(inicioActions.setLoading('carteiras', false))
  }
}

interface IniciarAtendimentoCarteirasProps extends GenericProps {
  tipoProduto: number
  consultorId: string
}

function* iniciarAtendimentoCarteiras({
  tipoProduto,
  consultorId
}: IniciarAtendimentoCarteirasProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      inicioController.iniciarAtendimentoCarteiras,
      tipoProduto,
      consultorId
    )
    yield put(
      atendimentoActions.startAtendimento(response?.data?.content?.cpfCnpj, 1)
    )
    const telefones: InfosChamada['telefones'] = []
    response?.data?.content?.telefones.forEach((telefone: Telefone) => {
      telefones.push({
        numero: `(${telefone.ddd}) ${telefone.numero}`,
        whatsapp: telefone.whatsapp,
        telefoneId: telefone.telefoneId
      })
    })
    yield put(
      atendimentoActions.setInfosContato(
        {
          nome: response?.data?.content?.nome,
          dataNascimento: moment(
            response?.data?.content?.dataNascimento
          ).format('DD/MM/YYYY'),
          cidade: response?.data?.content?.cidade,
          sexo: response?.data?.content?.sexo,
          clienteRiber: response?.data?.content?.clienteRiber,
          infosChamada: {
            telefones: telefones,
            dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
            horarioAgendamento: moment().format('HH:mm'),
            numeroChamada: [],
            observacoesAgendamento: '',
            tabulacaoChamada: null,
            tipoAgendamento: '',
            emChamada: false
          },
          atendimentoId: response?.data?.content?.atendimentoId,
          tipoProduto: response?.data?.content?.tipoProduto,
          campanhaCrmId: response?.data?.content?.campanhaCrmId,
          atendimentoAgendadoId: response?.data?.content?.atendimentoAgendadoId,
          consultorId: consultorId,
          tipoAtendimento: 1
        },
        response?.data?.content?.cpfCnpj
      )
    )
    if (window.location.pathname.toLowerCase() !== '/atendimento') {
      history.push('/atendimento')
    }
    yield put(
      atendimentoActions.setModalAtendimentoAgendado(
        !!response?.data?.content?.atendimentoAgendadoId,
        removeMaskCPF(response?.data?.content?.cpfCnpj)
      )
    )
  } catch (error) {
    handlerError(error, 'Não foi possível iniciar o atendimento da Carteira!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getCampanhas(): SagaIterator {
  try {
    yield put(inicioActions.setLoading('campanhas', true))
    const response = yield call(inicioController.getCampanhas)
    yield put(inicioActions.setCampanhas(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(inicioActions.setLoading('campanhas', false))
  }
}

interface IniciarAtendimentoCampanhasProps extends GenericProps {
  campanhaCrmId: string
}

function* iniciarAtendimentoCampanhas({
  campanhaCrmId
}: IniciarAtendimentoCampanhasProps): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      inicioController.iniciarAtendimentoCampanhas,
      campanhaCrmId
    )
    yield put(
      atendimentoActions.startAtendimento(response?.data?.content?.cpfCnpj, 1)
    )
    const telefones: InfosChamada['telefones'] = []
    response?.data?.content?.telefones.forEach((telefone: Telefone) => {
      telefones.push({
        numero: `(${telefone.ddd}) ${telefone.numero}`,
        whatsapp: telefone.whatsapp,
        telefoneId: telefone.telefoneId
      })
    })
    yield put(
      atendimentoActions.setInfosContato(
        {
          nome: response?.data?.content?.nome,
          dataNascimento: moment(
            response?.data?.content?.dataNascimento
          ).format('DD/MM/YYYY'),
          cidade: response?.data?.content?.cidade,
          sexo: response?.data?.content?.sexo,
          clienteRiber: response?.data?.content?.clienteRiber,
          infosChamada: {
            telefones: telefones,
            dataAgendamento: moment().add(1, 'days').format('YYYY-MM-DD'),
            horarioAgendamento: moment().format('HH:mm'),
            numeroChamada: [],
            observacoesAgendamento: '',
            tabulacaoChamada: null,
            tipoAgendamento: '',
            emChamada: false
          },
          atendimentoId: response?.data?.content?.atendimentoId,
          tipoProduto: response?.data?.content?.tipoProduto,
          campanhaCrmId: response?.data?.content?.campanhaCrmId,
          atendimentoAgendadoId: response?.data?.content?.atendimentoAgendadoId,
          consultorId: null,
          tipoAtendimento: 2
        },
        response?.data?.content?.cpfCnpj
      )
    )
    if (window.location.pathname.toLowerCase() !== '/atendimento') {
      history.push('/atendimento')
    }
    yield put(
      atendimentoActions.setModalAtendimentoAgendado(
        !!response?.data?.content?.atendimentoAgendadoId,
        removeMaskCPF(response?.data?.content?.cpfCnpj)
      )
    )
  } catch (error) {
    handlerError(error, 'Não foi possível iniciar o atendimento da Campanha!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

interface GetBigNumbersProps extends GenericProps {
  data: string
}

function* getBigNumbers({ data }: GetBigNumbersProps): SagaIterator {
  try {
    yield put(inicioActions.setLoading('bigNumbers', true))
    yield put(inicioActions.cleanBigNumbers())
    const response = yield call(inicioController.getBigNumbers, data)
    yield put(inicioActions.setBigNumbers(response?.data?.content))
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(inicioActions.setLoading('bigNumbers', false))
  }
}
export function* watchIniciarAtendimentoLead() {
  yield takeEvery(TYPES.INICIAR_ATENDIENTO_LEAD_SAGA, iniciarAtendimentoLead)
}

export function* watchGetQuantidadeLeads() {
  yield takeEvery(TYPES.GET_QUANTIDADE_LEADS_SAGA, getQuantidadeLeads)
}

export function* watchGetCarteiras() {
  yield takeEvery(TYPES.GET_CARTEIRAS_SAGA, getCarteiras)
}

export function* watchIniciarAtendimentoCarteiras() {
  yield takeEvery(
    TYPES.INICIAR_ATENDIMENTO_CARTEIRAS_SAGA,
    iniciarAtendimentoCarteiras
  )
}

export function* watchGetCampanhas() {
  yield takeEvery(TYPES.GET_CAMPANHAS_SAGA, getCampanhas)
}

export function* watchIniciarAtendimentoCampanhas() {
  yield takeEvery(
    TYPES.INICIAR_ATENDIMENTO_CAMPANHAS_SAGA,
    iniciarAtendimentoCampanhas
  )
}

export function* watchGetBigNumbers() {
  yield takeEvery(TYPES.GET_BIGNUMBERS_SAGA, getBigNumbers)
}

function* inicioSaga() {
  yield all([
    fork(watchGetQuantidadeLeads),
    fork(watchIniciarAtendimentoLead),
    fork(watchGetCarteiras),
    fork(watchIniciarAtendimentoCarteiras),
    fork(watchGetCampanhas),
    fork(watchIniciarAtendimentoCampanhas),
    fork(watchGetBigNumbers)
  ])
}

export default inicioSaga
