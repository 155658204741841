// const config = {
//   AMBIENTE: 'DESENV',
//   API_CRM: 'https://localhost:5001/api/',
//   API_FGTS: 'https://localhost:6001/api/'
// }

/** Ambiente de testes */
// const config = {
//   AMBIENTE: 'QA',
//   API_CRM: 'https://ribercred-hml.mazzafc.tech:1899/api/',
//   API_FGTS: 'http://ec2-18-213-234-127.compute-1.amazonaws.com:5050/api/'
// }

/** Ambiente de homologação */
// const config = {
//   AMBIENTE: 'HML',
//   API_CRM: 'https://apicrmhml.riber.app.br/api/',
//   API_FGTS: 'https://apifgtshml.riber.app.br/api/'
// }

/** Ambiente de produção */
const config = {
  AMBIENTE: 'PROD',
  API_CRM: 'https://apicrm.riber.app.br/api/',
  API_FGTS: 'https://apifgts.riber.app.br/api/'
}

export default config
